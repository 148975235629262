@import url('https://fonts.googleapis.com/css2?family=Delius+Swash+Caps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Pauline";
    src: local('Pauline'), url(./fonts/Pauline.woff) format('woff');
}


body {
    appearance: none;
    /*background: #1D23BF;*/
}

body::-webkit-scrollbar {
    height: 0;
    width: 0;
}


#thePoint {
    will-change: transform;
}

.button-container {
    @apply flex justify-center md:justify-start;
}

.button {
    @apply bg-gini-button-green text-base text-white font-Poppins font-bold rounded-3xl h-12 w-48
    transition ease-in-out  hover:scale-110  duration-300;
}

.content-title {
    @apply text-40   font-Poppins font-bold;
}

.content-paragraph {
    @apply text-white  text-base font-Poppins lg:max-w-[300px] xl:max-w-[326px] leading-[25px];
}

.content-div {
    @apply mx-auto flex flex-col lg:flex-row  items-start w-fit;
}

.slick-arrow {
    position: absolute !important;
    top: 100% !important;
}

.slick-center {
    transform: scale(1.25);
    transition: all 0.4s ease-in-out;
}

.slick-slide {
    transition: all 0.4s ease-in-out;
}


.slick-prev {
    left: 5% !important;
    z-index: 10;
    width: 50px !important;
    height: 50px !important;
}

.slick-next {
    right: 5% !important;
    z-index: 10;
    width: 50px !important;
    height: 50px !important;
}

.slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.slick-dots.slick-thumb li {
    width: 25px;
    height: 25px;
    margin: 0 8px;
    border-radius: 50px;
    transition: all 0.5s ease-in-out;
    border-color: #10D379;
    border-width: 3px;
}

.slick-dots.slick-thumb li.slick-active {
    background-color: #10D379;

}


#container {
    position: fixed;
    left: 50%;
    top: 0;
    visibility: hidden;
}

#map {
    overflow: visible;
}


.dropdown:hover {
    border-radius: 999px;
    outline: #FF8E46 solid 2px;
}

input:focus {
    outline: #FF8E46 solid 2px;
    border-color: transparent;
}

textarea:focus {
    outline: #FF8E46 solid 2px;
    border-color: transparent;
}